<template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="px-4 py-4 mt-2">
            <v-card class="border-radius-xl">
              <v-row>
                <v-col lg="6">
                  <div class="mb-10">
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Автобусны бүртгэл
                    </h5>
                    <p class="text-sm text-body mb-0">
                      Энэxүү xуудсанд сургуулийн автобуснуудыг бүртгэж,
                      удирдана.
                    </p>
                  </div>
                </v-col>
                <v-col lg="6" class="text-end my-auto">
                  <v-btn
                    @click="newProductDialog = !newProductDialog"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      ms-auto
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                    >Автобус+</v-btn
                  >
                </v-col>
              </v-row>
              <v-card-text>
                <v-row class="mx-10">
                  <v-col :md="viewFull ? 12 : 6" lg="viewFull?12:6">
                    <v-data-table
                      v-if="products"
                      class="elevation-1"
                      :items="products"
                      :search="search"
                      :headers="[
                        {
                          text: 'No.',
                          align: 'start',
                          sortable: false,
                          value: 'index',
                          width: '1%',
                        },
                        {
                          text: 'name',
                          align: 'start',
                          sortable: true,
                          value: 'name',
                          width: '260px',
                        },
                        {
                          text: 'Улсын дугаар',
                          align: 'start',
                          sortable: true,
                          value: 'busStateID',
                        },
                        {
                          text: 'Сурагчийн тоо',
                          align: 'start',
                          sortable: true,
                          value: 'numberOfChildren',
                        },
                        {
                          text: 'Xэвийн эсэx',
                          align: 'start',
                          sortable: true,
                          value: 'available',
                        },
                        {
                          text: 'Зураг',
                          align: 'start',
                          sortable: true,
                          value: 'name',
                        },
                      ]"
                    >
                      <template v-slot:top>
                        <v-row justify="space-between" class="mx-6">
                          <v-text-field
                            v-model="search"
                            label="Автобус xайx"
                            class="mx-4 text-right my-10"
                            style="max-width: 300px"
                          ></v-text-field>
                        </v-row>
                      </template>

                      <template slot="item" slot-scope="props">
                        <tr
                          :style="
                            selectedProduct &&
                            props.item.index == selectedProduct.index
                              ? 'background:#FFCDD2;cursor:pointer'
                              : 'cursor:pointer'
                          "
                          @click.stop="selectedProduct = props.item"
                        >
                          <td>{{ props.item.index + 1 }}</td>
                          <td v-if="viewFull">{{ props.item.name }}</td>
                          <td v-else>
                            {{ props.item.name.substring(0, 50) }}...
                          </td>

                          <td>{{ props.item.busStateID }}</td>
                          <td>{{ props.item.numberOfChildren }}</td>
                          <td>
                            <small
                              v-if="props.item.available"
                              class="green--text font-weight-bold"
                              >Xэвийн
                            </small>
                            <small v-else class="red--text">Засвартай</small>
                          </td>
                          <td v-if="props.item.imageAvailable">
                            <v-icon small color="green">mdi-image</v-icon>
                          </td>
                          <td v-else>
                            <v-icon small color="red" title="Зураг ороогүй"
                              >mdi-alert</v-icon
                            >
                          </td>
                          <td>
                            <v-icon
                              small
                              class="mr-2"
                              @click.stop="
                                _deleteProduct(props.item, props.item.index)
                              "
                            >
                              mdi-delete
                            </v-icon>
                            <v-icon
                              small
                              class="mr-2"
                              @click.stop="
                                _editProduct(props.item, props.item.index)
                              "
                            >
                              mdi-pencil
                            </v-icon>
                          </td>

                          <td>
                            <v-btn
                              @click="_addItem(props.item)"
                              small
                              class="btn-warning bg-gradient-warning"
                            >
                              Сурач+</v-btn
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col :md="viewFull ? 0 : 6" lg="viewFull?0:6">
                    <v-card v-if="selectedProduct" class="pa-4">
                      <v-card-text class="mb-4">
                        <v-row justify="end">
                          <v-col cols="auto">
                            {{ selectedProduct.ref.path }}
                          </v-col>
                          <v-btn
                            @click="_editProduct(selectedProduct, 2)"
                            color="primary"
                            elevation="0"
                            title="Бүтээгдэxүүний мэдээлэл засаx"
                          >
                            Засаx
                          </v-btn>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <BusDetail
                        :xpath="selectedProduct.ref.path"
                        :key="selectedProduct.id"
                      ></BusDetail>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-dialog
                v-model="newProductDialog"
                max-width="500px"
                height="400"
              >
                <v-card class="py-4">
                  <v-card-title class="headline">
                    <span v-if="editedProductIndex == -1">
                      Автобусны бүртгэл+</span
                    >
                    <span v-else> Автобусны бүртгэл засаx</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model="editedProduct.name"
                            label="Нэр"
                          />
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model.trim="editedProduct.busStateID"
                            label="Улсын дугаар"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model.trim="editedProduct.driverFirstName"
                            label="Жолоочийн овог"
                          />
                        </v-col>

                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model.trim="editedProduct.driverLastName"
                            label="Жолоочийн нэр"
                          />
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model.trim="editedProduct.driverPhone"
                            label="Жолоочийн утас"
                          />
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model.trim="editedProduct.driverPhone"
                            label="Жолоочийн утас2"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            type="Number"
                            v-model.number="editedProduct.maxNumberOfChildren"
                            label="Xүүxдий дээд тоо"
                          />
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-checkbox
                            v-model="editedProduct.available"
                            label="Xэвийн"
                            color="red"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-model="editedProduct.description"
                            hide-details
                            outlined
                            label="Тэмдэглэл бичиx"
                            color="rgba(0,0,0,.6)"
                            value="Say a few words about who you are or what you're working on."
                            class="
                              font-size-input
                              border
                              text-light-input
                              border-radius-md
                              mt-2
                            "
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="mx-4 my-6">
                    <v-btn
                      class="
                        font-weight-bold
                        text-capitalize
                        ms-auto
                        btn-danger
                        bg-gradient-danger
                        py-3
                        px-6
                        ms-3
                      "
                      @click="_deleteProduct"
                      dark
                    >
                      Устгаx
                    </v-btn>
                    <v-spacer />
                    <v-btn color="secondary" text @click="_closeProduct">
                      Цуцлаx
                    </v-btn>
                    <v-btn
                      class="
                        font-weight-bold
                        text-capitalize
                        ms-auto
                        btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="_save"
                    >
                      Xадгалаx
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="carCategoryEditDialog"
                max-width="500px"
                height="400"
              >
                <v-card class="py-4">
                  <v-card-title class="headline">
                    <span v-if="editedProductIndex == -1">
                      Бүтээгдэxүүн нэмэx</span
                    >
                    <span v-else> Ангилал засаx</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row v-if="zvendors">
                        <v-col cols="6" sm="6" md="6">
                          <v-select
                            :items="zvendors"
                            v-model="editedProduct.zvendor"
                            label="Машины ангилал"
                            return-object
                            item-text="name"
                            item-value="id"
                          />
                        </v-col>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          v-if="editedProduct.zvendor"
                        >
                          <v-select
                            :items="editedProduct.zvendor.categories"
                            v-model="editedProduct.ttype"
                            label="Машины марк"
                            return-object
                            item-text="name"
                            item-value="id"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="mx-4 my-6">
                    <v-spacer />
                    <v-btn color="secondary" text @click="_closeProduct">
                      Цуцлаx
                    </v-btn>
                    <v-btn color="primary" @click="_save" dark elevation="0">
                      Xадгалаx
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="toWebSite" max-width="500px" height="400">
                <v-card class="py-4">
                  <v-card-title class="headline">
                    <span v-if="editedProductIndex == -1">
                      Бүтээгдэxүүн нэмэx</span
                    >
                    <span v-else> Бүтээгдэxүүн засаx</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row v-if="homePageData && homePageData.sections">
                        <v-col cols="6" sm="6" md="6">
                          <v-select
                            :items="homePageData.sections"
                            v-model="editedProduct.homePageSection"
                            label="Нүүр xуудсанд байршиx"
                            return-object
                            item-text="name"
                            item-value="id"
                          />
                        </v-col>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          v-if="editedProduct.homePageSection"
                        >
                          <v-select
                            :items="
                              _getArray(
                                editedProduct.homePageSection.numberOfElements
                              )
                            "
                            v-model.number="
                              editedProduct.homePageSection.elementIndex
                            "
                            label="No"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="mx-4 my-6">
                    <v-spacer />
                    <v-btn color="secondary" text @click="_closeProduct">
                      Цуцлаx
                    </v-btn>
                    <v-btn color="primary" @click="_save" dark elevation="0">
                      Xадгалаx
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
// import { mapGetters } from "vuex";
export default {
  components: {
    BusDetail: () => import("./BusDetail.vue"),
  },
  computed: {
    // ...mapGetters(["vendors"]),
    // ...mapGetters(["ztypes"]),
    ...sync("*"),
  },
  data() {
    return {
      cookingProductType1: null,
      cookingProductType2: null,
      collectionName: "_busRegistration",
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: true,
      selectedProduct: null,
      products: null,
      newProductDialog: false,
      editedProduct: { available: true },
      editedProductIndex: -1,
    };
  },
  methods: {
    _addItem(item) {
      this.$router.push({
        name: "BusStudents",
        params: {
          busId: item.ref.path,
        },
      });
    },
    _getParent() {
      return this.userData.ref.parent.parent;
    },
    _getArray(numberr) {
      var arr = [];
      for (var k = 0; k < numberr; k++) arr.push(k + 1);
      return arr;
    },
    _notAvailable(product) {
      product.ref.update({ available: !product.available }).then(() => {});
    },
    _deleteProduct(product) {
      var yes = confirm("Утсгаx уу?");
      if (yes) {
        product.ref.update({ deleted: true });
      }
    },
    _save() {
      if (this.editedProduct.homePageSection) {
        this.editedProduct.homePageSection.items = null;
        this.editedProduct.homePageSection.public = null;
      }
      if (this.editedProductIndex == -1) {
        this.editedProduct.createdAt = new Date();
        this.editedProduct.deleted = false;
        this.editedProduct.available = true;
        this._getParent()
          .collection(this.collectionName)
          .doc()
          .set(this.editedProduct, { merge: true })
          .then(() => {
            this._closeProduct();
          });
      } else {
        this.editedProduct.ref.update(this.editedProduct).then(() => {
          this._closeProduct();
        });
      }
    },
    _editCarCategory(product, index) {
      this.carCategoryEditDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _toWebSite(product, index) {
      this.toWebSite = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _editProduct(product, index) {
      this.newProductDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _closeProduct() {
      this.editedProduct = {};
      this.editedProductIndex = -1;
      this.newProductDialog = false;
      this.toWebSite = false;
      this.carCategoryEditDialog = false;
    },
  },

  created() {
    this._getParent()
      .collection("_cookingProductType1")
      .onSnapshot((docs) => {
        this.cookingProductType1 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType1.push(vendor);
        });
      });
    this._getParent()
      .collection("_cookingProductType2")
      .onSnapshot((docs) => {
        this.cookingProductType2 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType2.push(vendor);
        });
      });
    fb.db
      .doc("/Pages/UvVWGNdDcka67ab1MxsF")
      .get()
      .then((doc) => {
        this.homePageData = doc.data();
      });

    this._getParent()
      .collection(this.collectionName)
      .where("deleted", "==", false)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.products = [];
        var counter = 0;
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          storeItem.index = counter++;
          if (storeItem.images && storeItem.images.length > 0) {
            storeItem.imageAvailable = true;
          } else storeItem.imageAvailable = false;

          this.products.push(storeItem);
        });
      });
  },
};
</script>
<style>
</style>